import styled from 'styled-components';
import { device } from '../../../../theme'

export const SectionWrap = styled.div`
.service1,.service2{padding-top:60px;padding-bottom:60px;}
h4{font-size: 60px;line-height: 1;}
h5{font-size: 52px;line-height: 70px;padding-bottom: 35px;}
.section-space{padding-top: 30px;padding-bottom: 30px;}
.sevice-img{margin-bottom:30px;}
@media ${device.medium}{
.service1,.service2{padding-top:40px;padding-bottom:40px;}
h4{font-size: 38px;line-height: 1;}
h5{font-size: 34px;line-height: 70px;padding-bottom:20px;}
.section-space{padding-top: 20px;padding-bottom: 20px;}
.sevice-img{padding-bottom:20px;}
}
@media ${device.small}{
.service1,.service2{padding-top:30px;padding-bottom:30px;}
h4{font-size: 28px;line-height: 1;}
h5{font-size: 24px;line-height: 1;padding-bottom:15px;}
.section-space{padding-top: 10px;padding-bottom: 10px;}
.sevice-img{padding-bottom:10px;}
}
.row{align-items: center!important;}
    .Tickmark{font-weight: 400;
    color: #f36d21;
    font-size: 24px;
    line-height: 1;
    margin-right:10px
    }
    .headstyle{display:flex;align-items: center;margin-bottom:15px}
    .headtag{color:#717171; display:none;}
    .service-bgcover .headtag{
        color:#ffffffa8;
    }
    .service-bgcover{
        li ,h5{
            color:#ffffff !important;
        }
        background:url(../../../../../servicesection-web1.jpg);
        background-position: center center;
        background-size: cover;}
    .service-bgcolor{background: #e8e8e84a;padding-top:60px}
    .commandash{width:35px;height:3px;background:#f36d21;margin: 10px;display:none;}
`;  