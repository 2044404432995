import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../../../components/ui/tabs/layout-one'
import { SectionWrap } from './style'
import { MdDone } from "react-icons/md";

const TabsSection = () => {
    return (
        <SectionWrap>
            <div fluid className="service-bgcolor service1">
                <Container>
                    <Row >
                        <Col lg={12}>
                            <p>Can handle complex transformations, multi-step operations, aggregating data from different data sources or data types and can load in parallel to many multiple destinations. </p>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="sershape-left sershape sevice-img">
                            <img src="../../../../discriptive analytics.png "></img>
                        </Col>
                        <Col lg={6}>
                            <Heading as="h5" mb="15px">Descriptive Analytics</Heading>
                            <ul>
                                <li><MdDone className="Tickmark" /> Can capture and transform the data from multiple heterogeneous sources like Excel, SQL, Oracle, Notepad, CSV and OData.</li>
                                <li><MdDone className="Tickmark" /> Can handle complex transformations, multi-step operations, aggregating data from different data sources or data types.</li>
                                <li><MdDone className="Tickmark" />Data can be loaded in parallel to many varied destinations</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="order-lg-1 order-2">
                            <Heading as="h5" mb="15px">Data Warehouse</Heading>
                            <ul>
                                <li><MdDone className="Tickmark" /> Allows business users to quickly access critical data from some
                                        sources all in one place. </li>
                                <li><MdDone className="Tickmark" /> Helps to reduce total turnaround time for analysis and reporting.</li>
                                <li><MdDone className="Tickmark" />Stores a large amount of historical data. This helps users to
                                     analyze different time periods and trends to make future predictions. </li>
                            </ul>
                        </Col>
                        <Col lg={6} className="sershape-right sershape order-lg-2 order-1 sevice-img">
                            <img src="../../../../datawarehouse.png" class></img>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div fluid className="service-bgcover service2">
                <Container>
                    <Row className="section-space">
                        <Col lg={6} className="sershape-right sershape sevice-img">
                            <img src="../../../../olap-cube.png" class></img>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <h5 className="mb-3">OLAP Cube</h5>
                            <ul>
                                <li><MdDone className="Tickmark" />Cube allows the data to be analyzed in the most logical way. </li>
                                <li><MdDone className="Tickmark" /> Supports hierarchical data analyzation</li>
                                <li><MdDone className="Tickmark" />Helps you to avoid resource contention with the source system </li>
                                <li><MdDone className="Tickmark" />It allows users to analyze data with a host of tools, including Excel/SSRS/Power-BI/Tableau.</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="order-lg-1 order-2">
                            <h5 className="mb-3">End User Reporting</h5>
                            <ul>
                                <li><MdDone className="Tickmark" />Non-technical users can do their own data analysis.</li>
                                <li><MdDone className="Tickmark" /> You can stop guessing and start making data-driven decisions.</li>
                                <li><MdDone className="Tickmark" />Offer the dashboards with graphic data visualizations in an effective manner to easily and quickly analyze it to make business decisions.</li>
                                <li><MdDone className="Tickmark" />Can enable information to flow upward, downward, or laterally, reaching the intended recipient in a timely manner</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="sershape-right sershape order-lg-2 order-1 sevice-img">
                            <img src="../../../../user-reports.png" class></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        </SectionWrap>
    )
}

export default TabsSection
